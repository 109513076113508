import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

export default function WebsiteMeta({
  title,
  titleFull,
  description,
  lang,
  meta,
  image,
  imageDimensions,
  article,
  screenshot,
  hidden,
}) {
  const { site, settings } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle
            titleTemplate
            defaultDescription
            siteUrl
            defaultImage
            twitterUsername
          }
        }

        settings: sanitySettings(_id: { eq: "site-settings" }) {
          siteTitle
          siteDescription
        }
      }
    `
  )

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const { pathname } = useLocation()

  const seo = {
    title: title || settings.siteTitle || defaultTitle,
    description:
      (description && description.length > 1 ? description : "") ||
      settings.siteDescription ||
      defaultDescription,
    image: `${image || siteUrl + defaultImage}`,
    url: `${siteUrl}${pathname}`,
    ogType: article ? "article" : "website",
    robots: screenshot ? "nofollow, noindex" : "",
    imageWidth:
      imageDimensions && typeof imageDimensions[0] === "number"
        ? imageDimensions[0]
        : "",
    imageHeight:
      imageDimensions && typeof imageDimensions[1] === "number"
        ? imageDimensions[1]
        : "",
  }

  const robots = [
    {
      property: `robots`,
      content: seo.robots,
    },
  ]

  const hiddenPageMeta = hidden ? robots : []

  const screenshotMeta = screenshot ? robots : []

  const imageMeta =
    seo.image && seo.imageWidth && seo.imageHeight
      ? [
          {
            property: `image`,
            content: seo.image,
          },
          {
            property: `og:image`,
            content: seo.image,
          },
          {
            property: `og:image:width`,
            content: seo.imageWidth,
          },
          {
            property: `og:image:height`,
            content: seo.imageHeight,
          },
          {
            property: `twitter:image`,
            content: seo.image,
          },
        ]
      : ""

  const twitterUsernameMeta = twitterUsername
    ? [
        {
          property: `twitter:creator`,
          content: twitterUsername,
        },
      ]
    : []

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title || titleFull}
      titleTemplate={titleFull || titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:type`,
          content: seo.ogType,
        },
        {
          property: `og:title`,
          content: seo.title || titleFull,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: seo.title || titleFull,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
      ]
        .concat(hiddenPageMeta)
        .concat(screenshotMeta)
        .concat(imageMeta)
        .concat(twitterUsernameMeta)
        .concat(meta)}
    />
  )
}

WebsiteMeta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  imageDimensions: [1200, 630],
}

WebsiteMeta.propTypes = {
  title: PropTypes.string,
  titleFull: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  imageDimensions: PropTypes.array,
  article: PropTypes.bool,
  screenshot: PropTypes.bool,
  hidden: PropTypes.bool,
}
